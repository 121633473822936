@import 'style/vars';

.base {
  display: flex;
  flex-direction: column;
  min-height: 101vh; // Hack: When set to 100, on windows edge flickers when input is selected as scrollbar appears
  background-image: linear-gradient(#477ed695, #0a6fda6e), url('/assets/images/bg-mountains.png');
  background-size: 100% 100%;

  a {
    text-decoration: none;
  }
}
