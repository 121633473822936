@import 'style/vars';
$compare-cell-width: 120px;
$bullet-color: rgb(26, 145, 3);
.base {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $font-color-main;

  .spinnerSvg {
    align-self: center;
    margin: 30px;
    fill: $font-color-placeholder;
    width: 50px;
    max-width: 100%;
    animation-name: spin;
    animation-duration: 6000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff9;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 5px;
      border-bottom: 1px solid $light-grey-border-color;
      box-sizing: border-box;

      div {
        width: $compare-cell-width;
        text-align: center;
      }

      .number {
        width: 50px;
      }

      .nameCell {
        width: 30%;
        word-break: break-all;
      }
    }

    .header {
      box-shadow: 0 1px 2px 0 #e9effa;
      background-color: $primary-color-dark;
      color: white;
      padding: 12px 5px;
      border-radius: 5px;
    }

    .viewBtn {
      button {
        width: calc(#{$compare-cell-width} - 1px);
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .dot {
    height: 14px;
    width: 14px;
    border: 1px solid $light-grey-border-color;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin: 1px;
  }
  .filled {
    background-color: $bullet-color;
  }

  .debugSwitchBtn {
    width: 100px;
    text-align: right;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  .debugContainer {
    background-color: #fff9;
  }

  .newSearchBtn {
    width: 100%;
  }
}
