$dark-grey: #616161;
$light-grey: #f2f2f2;

$dark-blue: #2b5992;
$blue: #4c7ff6;
$light-blue: #dce7f5;

$green: #30c87f;

$orange: #f78d1e;
