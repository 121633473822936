@import 'style/vars';
@import 'style/mixins';

.base {
  .cardsContainer {
    @include cardsContainer;
    min-height: auto !important;
    flex-direction: column;
  }
  .card {
    @include card;
    margin-bottom: 30px;
    width: auto;
    height: 440px;

    .cardContent {
      height: 100%;
      display: flex;
      flex-direction: column;

      .buttonContainer {
        margin-top: auto;
      }
    }

    .paragraph {
      margin: 25px 0;
      display: flex;
      align-items: center;
      svg {
        width: 1.5em;
        height: 1.5em;
        padding-right: 20px;
      }
      .likeIcon svg {
        color: $green;
      }
    }
  }

  .card3 {
    .icon {
      color: $green;
    }
  }

  .disclaimerLink {
    cursor: pointer;
    color: rgb(228, 228, 228);
    margin-top: 20px;
    margin-left: 20px;

    &:hover {
      color: white;
    }
  }

  .disclaimerContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
  }
}

@media only screen and (min-width: $tablet-width) {
  .base {
    .cardsContainer {
      .card {
        margin: 20px;
        width: 350px;

        .paragraph {
          svg {
            width: 2em;
            height: 2em;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop-width) {
  .base {
    .cardsContainer {
      .card {
        min-width: 250px;
        margin: 10px;
        font-size: 13.5px;

        .customCardTitle div {
          font-size: 23px;
        }

        .cardContent {
          .buttonContainer button {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $landscape-width) {
  .base {
    .cardsContainer {
      .card {
        margin: 20px;
        width: 350px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .base {
    .cardsContainer {
      .card {
        width: 400px;
        font-size: 16px;
        .customCardTitle div {
          font-size: 30px;
        }
      }
    }
  }
}
