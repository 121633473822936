@import 'style/vars';

.base {
  .missingData {
    font-style: italic;
    font-size: 9px;
  }

  .dots {
    display: flex;
    svg {
      padding: 1px;
      width: 0.8em;
    }

    .tooltip {
      position: absolute;
      top: -10px; /* Ajusta según necesites */
      left: 50%;
      transform: translateX(-50%);
      background-color: black;
      color: white;
      padding: 5px;
      border-radius: 5px;
      /* Otros estilos para tu tooltip */
    }
  }
}
