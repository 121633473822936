@import './colors';

$small-tablet-width: 481px; /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
$tablet-width: 641px; /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
$desktop-width: 961px; /* tablet, landscape iPad, lo-res laptops ands desktops */
$landscape-width: 1025px;

$header-height: 60px;
$header-height-big: 100px;
$margin-base: 15px;
$box-shadow: 3px 3px 3px 0 lightgrey;

$primary-color: $blue;
$primary-color-dark: $dark-blue;
$primary-color-light: $light-blue;
$primary-color-hover: darken($primary-color, 20%);

$font-family-main: 'Inter', 'Nunito Sans', 'Helvetica Neue', sans-serif;
$font-family-secondary: 'Nunito Sans';
$font-color-main: black;
$font-color-placeholder: #6f81a5;
$font-color-secondary: $light-grey;
$font-color-error: rgb(228, 26, 26);
$font-size: 15px;
$font-size-h1: 30px;
$font-size-h2: 25px;
$font-size-h3: 20px;
$font-size-small-1: 14px;
$font-size-small-2: 13px;

$input-background-color: #f3f6fe;
$light-grey-background: $light-grey;
$light-grey-border-color: darken($light-grey, 10%);
$button-font-color: $light-grey;

//Table
$table-color: $light-blue;
