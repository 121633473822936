@import 'style/vars';

$button-bg-active: $primary-color;
$button-bg-active-hover: $primary-color-hover;

.base {
  border-radius: 10px;
  border-width: 0px;
  padding: 13px 20px;
  font-family: $font-family-main;
  font-size: $font-size;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;

  &.primary {
    background-color: $button-bg-active;
    color: white;

    &:hover {
      background-color: $button-bg-active-hover;
      color: white;
    }
  }

  &.secondary {
    background-color: white;
    color: $button-bg-active;
    border: 1px solid $button-bg-active;
    &:hover {
      color: $button-bg-active-hover;
      border: 1px solid $button-bg-active-hover;
    }
  }

  &.green {
    background-color: $green;
  }

  &.orange {
    background-color: $orange;
  }

  &.white {
    background-color: white;
    color: black;
  }

  &.loading {
    cursor: wait !important;
  }

  &:disabled {
    background-color: $font-color-secondary;
    color: $dark-grey;
    cursor: default;
    border: 1px solid rgb(170, 169, 169);
    &:hover {
      background-color: $font-color-secondary;
      color: $dark-grey;
      cursor: default;
      border: 1px solid rgb(170, 169, 169);
    }
  }

  &.big {
    font-size: 22px;
  }

  .subtitle {
    font-size: 0.8rem;
    margin-top: 5px;
  }
}
