@import 'style/vars';
@import 'style/mixins';

$BLUE_COLOR: $blue;

.base {
  margin: 20px;
  color: white;
  display: flex;
  justify-content: center;

  .content {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .subtitle {
      max-width: 800px;
      margin-right: 70px;
      margin-left: 70px;
      margin-bottom: 35px;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
    }  
  }
}

@media only screen and (min-width: $desktop-width) {
  .base {
    .content {
      .subtitle {
        font-size: 30px;
      }
    }
  }
}

