@import 'style/vars';
@import 'style/mixins';

$INPUT_FONT_SIZE: 15px;
$SELECTED_COLOR: $primary-color;
$FONT-COLOR: $font-color-placeholder;

.base {
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 22px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: $FONT-COLOR;
    font-size: $INPUT_FONT_SIZE;
    line-height: 1;
    left: 16px;

    &.filled {
      transform: translate(0, 8px) scale(0.8);
      color: grey;
    }
  }

  &:focus-within label {
    transform: translate(0, 8px) scale(0.8);
    color: $SELECTED_COLOR;
  }

  input {
    padding: 25px 15px 14px 15px;
    background-color: $input-background-color;
    font-size: $INPUT_FONT_SIZE;
    font-weight: bold;
    border: 0;
    border-radius: 3px;
    font-family: $font-family-main;
    &:focus {
      border: 1px solid $SELECTED_COLOR;
    }
    &.error {
      border: 1px solid $font-color-error;
    }
  }

  .inputError {
    @include input-error;
  }
  i {
    position: absolute;
    right: 20px;
    top: 30px;
    color: $FONT-COLOR;
    cursor: pointer;
  }
}

.btnContainer {
  float: right;
}
