$WIDTH: 250px;

.ribbonContainer {
  position: absolute;
  right: 0;
  width: $WIDTH;
  z-index: 0;

  .ribbonContainerInner {
    overflow: hidden; /* required */
    width: $WIDTH; /* for demo only */
    height: 200px; /* some non-zero number */
    margin: 0; /* for demo only */
    position: relative; /* required for demo*/

    .ribbon {
      margin: 0;
      background: black;
      color: white;
      padding: 0em 0;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(18%) translateY(0%) rotate(45deg);
      transform-origin: top left;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        margin: 0 -1px; /* tweak */
        width: 100%;
        height: 100%;
        background: black;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }

      h4 {
        font-size: 32px;
        width: 100px;
        text-align: center;
        letter-spacing: 2px;
        line-height: 0;
      }
    }
  }
}
