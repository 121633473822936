@import 'style/vars';
@import 'style/mixins';

.base {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  padding: 20px 20px;
  background-color: rgb(238, 238, 238);
  color: rgb(33, 33, 33);
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.101);

  .exportResultsContainer {
    display: flex;
    .exportResults {
      cursor: pointer;
      display: flex;
      margin-right: 20px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 14px;
      color: darken($primary-color, 30%);
      &:hover {
        color: rgb(211, 158, 87);
      }
      svg {
        padding-right: 5px;
      }
      &.exportLoading {
        cursor: wait;
        color: grey;
      }
    }
  }

  .headerRow {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid $light-grey-border-color;
  }

  .loading {
    margin: 20px;
  }

  .error {
    @include errorMessage;
  }
}
