@import 'style/vars';

.base {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.separator {
  margin: 20px -20px;
  border-bottom: 1px solid rgb(226, 226, 226);
}

@media only screen and (min-width: $small-tablet-width) {
  .base {
    font-size: 30px;

    &.small {
      font-size: 23px;
    }
  }
}
