@import 'style/vars';

.base {
  .commercial {
    // font-weight: bold;
    // font-weight: 600;
  }
  .alternativeApplicantName {
    color: grey;
  }
}
