@import 'style/vars';

$text-color: white;
$text-color-secondary: $primary-color-light;

.base {
  height: $header-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;

  &.centered {
    height: $header-height-big;
    justify-content: center;
    align-items: end;
  }
  .logo {
    display: flex;
    align-items: center;
    height: calc(#{$header-height} - 2px);
    width: auto;
    padding-left: 2px;
  }

  .navigation {
    padding-left: 80px;
    a {
      color: $text-color;
      text-decoration: none;
      padding-right: 30px;
      font-weight: bold;

      &:hover {
        color: $text-color-secondary;
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 50px;
    .menuIcon {
      cursor: pointer;
      color: $text-color;
      &:hover {
        color: $text-color-secondary;
      }
    }

    .dropdown {
      position: absolute;
      right: 10px;
      top: calc(#{$header-height} - 10px);
      width: 225px;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 4px;
      box-shadow: 2px 2px 2px 1px rgba(191, 191, 191, 0.2);
      background-color: white;
      font-size: $font-size;

      div {
        padding: 14px;
        cursor: pointer;
        color: $blue;

        &:hover {
          background-color: $font-color-secondary;
        }
      }
      a {
        text-decoration: none;
        padding: 0;
        color: $blue;
      }

      .separator {
        border-top: 1px solid rgb(210, 210, 210);
      }
    }
  }
}
