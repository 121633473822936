@import 'style/vars';
@import 'style/mixins';

.base {
  text-align: center;

  .image {
    margin: 60px 0;
  }

  .text {
    color: $dark-grey;
    margin: 20px;
  }
  .link {
    margin: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
}
