@import 'style/vars';

.base {
  margin: 0 30px;
  padding: 7px;
  border-radius: 3px;
  border-width: 0px;
  font-size: $font-size;
  letter-spacing: 1px;
  cursor: pointer;
  background-color: white;
  color: $font-color-main;

  &:hover {
    background-color: $font-color-secondary;
  }

  &:disabled {
    color: $light-grey-border-color;
    cursor: default;
  }
}
