@import 'style/vars';
@import 'style/mixins';

.base {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans';
  min-width: 1200px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #3a3a3a9f;
  :last-child div {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .header {
    display: flex;
    flex-direction: row;
    background-color: $primary-color-dark;
    color: white;
    padding: 12px 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 1em;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid $light-grey-border-color;
    box-sizing: border-box;
  }

  .loading {
    margin: 20px;
  }

  .error {
    color: $font-color-error;
    font-size: $font-size-small-2;
    margin: 10px;
    text-align: center;
  }
}
