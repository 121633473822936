@import 'style/vars';
@import 'style/mixins';

.base {
  .dropdownContainer {
    padding: 10px 0;
    .error {
      @include input-error;
    }
  }
}
