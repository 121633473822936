@import 'style/vars';
@import 'style//mixins';

.base {
  @include cardsContainer;

  // @media only screen and (max-width: 1600px) {
  flex-direction: column;
  // }

  @media only screen and (max-width: 980px) {
    flex-direction: column-reverse;
  }

  .disclaimer {
    margin-left: 30px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 330px;

    @media only screen and (max-width: 980px) {
      margin-left: 0;
    }
  }

  .card {
    @include card;
    text-align: center;

    .welcome {
      margin-bottom: 30px;
    }
  }

  .formContainer {
    display: flex;
    justify-content: center;

    .form {
      width: 100%;
      .signUpError {
        color: $font-color-error;
        font-size: $font-size-small-2;
        margin-bottom: 10px;
      }
      .btnContainer {
        button {
          width: 100%;
        }
      }
      .footerText {
        margin-top: 20px;
        font-size: $font-size-small-1;
        .line {
          border-bottom: 0.8px solid rgb(164, 164, 164);
          margin: 20px 0;
        }
      }

      a {
        text-decoration: none;
        color: $primary-color;
        font-weight: bold;

        &:hover {
          color: $primary-color-hover;
        }
      }

      .reCaptcha {
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (min-width: $tablet-width) {
  .base {
    .card {
      min-width: 600px;
      .form {
        .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .col {
            width: 100%;
          }
          .left {
            margin-right: 5px;
          }
          .right {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
