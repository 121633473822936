@import 'style/vars';
@import 'style/mixins';

.base {
  @include card;
  background: black;
  color: white;
  width: 130px !important;
  min-width: 330px !important;
  height: 520px;

  .title div {
    font-size: 22px;
  }
  .cardContent {
    font-size: 12px;
    line-height: 20px;
    // margin: 15px;

    .close {
      cursor: pointer;
      padding-top: 20px;
      font-size: 18px;
      text-align: center;
      text-decoration: underline;
    }
  }
}
