@import 'style/vars';
@import 'style/mixins';

.base {
  .cardsContainer {
    @include cardsContainer;
    min-height: auto !important;
    margin-bottom: 20px;

    .card {
      @include card;
      text-align: center;

      .heading {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .text {
        color: $font-color-placeholder;
        line-height: 30px;
        font-size: 18px;

        a {
          color: $font-color-placeholder;

          &:hover {
            color: $primary-color;
          }
        }
      }

      .line {
        border-bottom: 0.8px solid rgb(164, 164, 164);
        margin: 40px 0;
      }

      .existingUsers {
        margin-bottom: 20px;
        font-size: 15px;
        a {
          color: $primary-color;
          font-weight: bold;
          &:hover {
            color: $primary-color-hover;
          }
        }
      }
    }
  }
}
