@import 'style/vars';

$panel-content-width: 200px;
$panel-color: $orange;
$box-shadow: -2px 2px 7px 2px rgba(73, 73, 73, 0.449);

.base {
  position: fixed;
  right: -$panel-content-width + 5px;
  transition: right 0.6s;
  z-index: 10;
  top: 50%;

  &.opened {
    right: 0;
  }

  .sidepanel {
    position: relative;
    margin-top: -100%;

    .panelBtnContainer {
      position: absolute;
      top: 30px;

      z-index: -1;

      .panelBtn {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: -7px;
        color: white;
        font-weight: bold;
        width: 100px;
        height: 80px;
        background-color: $panel-color;
        border-radius: 30px 0 0 30px;
        box-shadow: $box-shadow;
        transition: width 0.5s, margin 0.5s;
        margin-left: -100px;

        &.opened {
          width: 70px;
          margin-left: -65px;
        }
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
      :hover {
        background-color: lighten($panel-color, 5%);
      }
    }

    .contentContainer {
      width: $panel-content-width;

      z-index: 10;
      display: flex;
      align-items: center;

      .content {
        padding: 30px 20px;
        border: 1px solid $panel-color;
        background-color: $panel-color;
        border-radius: 30px 0 0 30px;
        box-shadow: $box-shadow;

        a .box :hover {
          color: $dark-blue;
        }

        .box {
          margin: 30px 0;
          background-color: white;
          border-radius: 10px;

          .boxContent {
            padding: 20px;
            font-weight: bold;
            color: black;

            .emphasized {
              color: red;
            }

            .row {
              margin: 12px 0;
              display: flex;
              align-items: center;

              .icon {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
  }
}
