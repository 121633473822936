@import 'style/vars';

.base {
  .rowContainer {
    border-bottom: 1px solid $light-grey-border-color;
    &:hover {
      background-color: rgb(244, 244, 244);
    }

    .error {
      color: red;
      text-align: center;
      font-size: 12px;
      padding-bottom: 5px;
    }

    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      color: rgb(48, 48, 48);
      padding: 15px 0;
      font-size: 12px;
      box-sizing: border-box;

      svg {
        cursor: pointer;
        &:hover {
          background-color: #d6d6d6;
          border-radius: 50px;
        }
      }

      .clipboard {
        font-size: 10px;
        svg {
          padding-top: 3px;
          cursor: pointer;
          color: rgb(86, 86, 86);
          &:hover {
            background-color: transparent !important;
            color: rgb(6, 217, 94);
          }
        }
      }

      .report {
        flex-direction: column;
        .link {
          border: 0;
          background: 0;
          font-size: 11px;
          padding: 3px 4px;
          cursor: pointer;
          color: $primary-color;

          &:hover {
            border-radius: 10px;
            border-width: 0px;
            color: $primary-color-dark;
          }
          &:enabled:active {
            background-color: rgb(232, 232, 232);
            border-radius: 5px;
            box-shadow: inset 1px 1px 5px rgb(219, 218, 218);
          }
          &.loading {
            cursor: wait;
            color: grey;
          }
        }
      }
    }
  }
}
