.base {
  min-height: 30px;
  width: calc(100% - 20px);
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  border-left: 10px solid #f3f6fe;
  border-bottom: 10px solid #f3f6fe;
  padding: 5px;
  box-sizing: border-box;

  .inputValsContainer {
    background-color: white;
    height: 33px;
    vertical-align: middle;
    margin-bottom: 10px;

    .inputValWord,
    .inputAND {
      display: inline-block;
      margin-right: 5px;
      // height: 30px;
      vertical-align: middle;
    }

    .inputValWord {
      background-color: rgb(149, 245, 203);
      border-radius: 8px;
      padding: 5px 15px 0 15px;
      height: 30px;
    }

    .inputAND {
      font-style: italic;
      color: rgb(175, 171, 171);
      font-size: 0.75rem;
    }
  }

  input {
    box-sizing: border-box;
    height: 33px;
    border: 1px solid #fff;
    box-shadow: none;
    appearance: unset;
    padding: 0 8px;
    flex-grow: 1;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }
}
