@import 'style/vars';
@import 'style/mixins';

.base {
  @include cardsContainer;

  .card {
    @include card;

    .section {
      margin-bottom: 20px;
    }

    .subCategoriesContainer {
      position: relative;
      // margin-bottom: 20px;
      padding: 0 0 0 10px;

      .subCategory {
        display: inline-block;
        vertical-align: top;
        width: 30%;
        min-width: 400px;
        padding: 2px;
        margin: 0 0 2px 0;
        font-size: 0.97rem;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }

    .errorMessage {
      @include errorMessage;
    }
  }
}

@media only screen and (min-width: $tablet-width) {
  .base {
    .card {
      max-width: $tablet-width;
    }
  }
}

@media only screen and (min-width: $desktop-width) {
  .base {
    .card {
      max-width: $desktop-width;
    }
  }
}
