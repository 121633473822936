@import 'style/vars';

.base {
  background-color: white;
  display: flex;
  flex-direction: column;

  .pageBreak {
    page-break-before: always;
  }

  &.pdf .report {
    font-size: 10px;
  }

  .report {
    width: 100%;
    background-color: white;
    // color: $primary-color-dark;
    color: #444;
    font-size: 12px;

    .title {
      color: $primary-color-dark;
    }
    .loading {
      width: 100%;
      margin: 20px;
    }
    .error {
      color: $font-color-error;
    }

    .section {
      margin: 50px 0;
      .subTitle {
        margin: 20px 0;
        font-weight: bold;
      }
    }
  }
}
