.base {
  label {
    cursor: pointer;
  }

  .disabled {
    cursor: default;
    color: rgb(110, 110, 110);
  }

  :disabled {
    cursor: auto;
  }
}
