$border-color: rgba(189, 189, 189, 0.441);

.base {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  width: 170px;
  max-height: 3.3rem;
  word-wrap: break-word;
  &.opened {
    max-height: none;
  }
  &.closed {
    .withOverflow {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  svg {
    cursor: pointer;
    &:hover {
      background-color: #d6d6d6;
      border-radius: 50px;
    }
  }
  &.first {
    width: 8px;
    font-weight: bold;
  }
  &.score {
    width: 25px;
  }
  &.country {
    width: 40px;
  }
  &.intersection {
    width: 45px;
  }
  &.patentNum {
    width: 120px;
  }
  &.classes {
    width: 200px;
  }
  &.title,
  &.terms {
    min-width: 200px;
    width: calc(50vw - 530px);
  }
}
