@import 'style/vars';
@import 'style/mixins';

.base {
  .cardsContainer {
    @include cardsContainer;
    min-height: auto !important;
  }

  .error {
    @include input-error;
    margin-top: -10px;
    margin-bottom: 7px;
  }

  .card {
    @include card;

    .cardContent {
      display: flex;
      flex-direction: column;

      .blockOne {
        margin-bottom: 5px;
        .row {
          // display: flex;
          min-height: 80px;
          align-items: center;

          .formInputContainer {
            position: relative;
            min-width: 100%;
          }

          .inputContainer {
            position: relative;
            min-width: 100%;
            padding: 10px 0;
          }
        }
      }

      .blockTwo {
        .instructions {
          font-weight: bold;
          padding-top: 10px;
        }
        .textGuide {
          display: flex;
          font-style: italic;
          color: $primary-color;
          font-size: 14px;
          margin: 10px;
          cursor: pointer;
          svg {
            margin-top: 2px;
            margin-right: 4px;
          }

          &:hover {
            color: $primary-color-hover;
          }
        }
        .searchOptions {
          display: flex;
          margin: 20px 0;

          .connector {
            margin: 0 10px;
          }
        }
        .searchTextContent {
          position: relative;

          textarea {
            @include text-input;

            width: 100%;
            height: 120px;

            &::placeholder {
              font-family: $font-family-main;
              color: $font-color-placeholder;
              font-size: 15px;
            }
          }
        }
        .searchTextError {
          textarea,
          button {
            border: 1px solid red;
          }
        }

        .buttonContainer {
          margin-top: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: $small-tablet-width) {
  /* For desktop also */
  .base {
    .cardsContainer {
      margin-bottom: 40px;
    }
    .card {
      margin: 0 20px;
      width: $small-tablet-width;
    }
  }
}

$MARGIN-BLOCK: 30px;

@media only screen and (min-width: $desktop-width) {
  /* For desktop: */
  .base {
    .card {
      width: $desktop-width;
      .cardContent {
        display: flex;
        flex-direction: row;
        .block {
          width: 50%;
          .blockOne {
            margin-right: $MARGIN-BLOCK;
            .tooltiptext {
              visibility: hidden;
              width: 240px;
              font-size: 14px;
              background-color: $primary-color-hover;
              color: #fff;
              margin-left: -143px;
              margin-top: 35px;
              text-align: center;
              border-radius: 6px;
              padding: 5px 5px;
              /* Position the tooltip */
              position: absolute;
              z-index: 1;
            }

            .tooltiptext:before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              position: absolute;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 10px solid $primary-color-hover;
              //left: -8px;
              top: -8px;
              left: 124px;
            }

            .icon:hover {
              .tooltiptext {
                visibility: visible;
                display: inline;
              }
            }

            .mobileTooltiptext {
              display: none;
            }
          }
          .blockTwo {
            margin-left: $MARGIN-BLOCK;
          }
        }
      }
    }
  }
}
