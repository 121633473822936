@import 'style/vars';

.base {
  margin: 30px;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  color: white;
  text-align: center;
}

@media only screen and (min-width: $small-tablet-width) {
  .base {
    margin: 40px;
    font-size: 40px;
    line-height: 50px;
  }
}

@media only screen and (min-width: $desktop-width) {
  .base {
    margin: 50px;
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (min-width: $landscape-width) {
  .base {
    font-size: 60px;
  }
}
