@import 'style/vars';
@import 'style/mixins';

.card {
  @include card;
  text-align: center;
  color: $font-color-main;

  flex-direction: column !important;

  //   @media only screen and (min-width: 961px) {}

  //     flex-direction: row;
  // }

  .formContainer {
    display: flex;
    justify-content: center;

    .form {
      width: 100%;

      .loginError {
        color: $font-color-error;
        font-size: $font-size-small-2;
        margin-bottom: 10px;
      }

      .btnContainer {
        width: 100%;
        margin: 20px 0;
        button {
          width: 100%;
          margin: 0;
        }
      }

      .forgotPassword a {
        cursor: pointer;
        text-decoration: none;
        color: $font-color-main;
        font-size: $font-size;
        color: $primary-color;

        &:hover {
          color: $primary-color-hover;
        }
      }

      .recaptcha {
        display: flex;
        justify-content: center;
        padding-top: 10px;
      }

      .createAccountContainer {
        margin-top: 50px;
        .createAccount {
          background-color: #f5f5fa;
          color: #8083a3;
          margin: 0 -25px;
          margin-top: auto;
          padding: 30px;
        }

        a {
          text-decoration: none;
          padding-left: 10px;
          color: #8083a3;
          font-weight: bold;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
}
