@import 'style/vars';
$highlight-color: rgb(255, 249, 70);
.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    margin-bottom: 20px;
    font-size: $font-size-h3;

    .filename {
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: calc(100vh - #{$header-height} - 230px);

    .textsContainer {
      position: relative;
      width: 30%;
      max-height: 100%;

      .textsContainerTitle {
        position: absolute;
        top: -40px;
        left: 10px;
        font-size: $font-size-h3;
      }

      .textBox {
        padding: 20px;
        border: 0.6px solid $light-grey-border-color;
        margin-bottom: 5px;
        border-radius: 3px;
        box-shadow: 1px 1px 3px $light-grey-border-color;
        background-color: #fff9;

        &.activeTextBox {
          border-color: $dark-grey;
        }

        &:hover {
          border-color: grey;
          cursor: pointer;
        }

        .textTitle {
          font-weight: bold;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    .searchDocument {
      width: 70%;
      padding: 20px;
      max-height: 100%;
      overflow-y: auto;
      white-space: pre-line;
      margin-left: 20px;
      border: 0.6px solid $light-grey-border-color;
      background-color: #fff9;
    }
  }
  .btnWrapper {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .selectedTextHighlight {
    background-color: $highlight-color;
  }
}
