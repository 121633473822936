@import 'style/vars';
@import 'style/mixins';

$CARD_HEIGHT: 360px;

.base {
  z-index: 9;
  .topSection {
    margin: 0 80px;
    display: flex;
    justify-content: center;

    .title {
      max-width: 1150px;
    }
  }

  .cardsContainer {
    @include cardsContainer;
    min-height: auto !important;
    flex-direction: column;
  }

  .card {
    @include card;
    margin-bottom: 30px;
    width: auto;
    height: $CARD_HEIGHT;
    line-height: 1.5;

    .cardContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: 1.35em;

      .paragraph {
        margin: 20px 25px;
        display: flex;
        align-items: center;
      }

      .buttonContainer {
        margin-top: auto;
        button {
          font-size: 19px;
        }
      }
    }

    &.greenCard {
      background-color: $green;
      color: white;

      .paragraph {
        margin-left: 15px;
        margin-right: 18px;
        display: flex;
        align-items: center;
      }

      .icon {
        padding-right: 15px;
        color: white;
        svg {
          width: 1.4em;
          height: 1.4em;
          background-color: black;
          // border: 1px solid black;
          border-radius: 5px;
        }
      }
    }
  }
}

@media only screen and (min-width: $tablet-width) {
  .base {
    .cardsContainer {
      .card {
        margin: 20px;
        width: 350px;
        .section {
          svg {
            width: 2em;
            height: 2em;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $desktop-width) {
  .base {
    .cardsContainer {
      .card {
        min-width: 250px;
        margin: 10px 40px;
        font-size: 1.08em;
      }
    }
  }
}

@media only screen and (min-width: $landscape-width) {
  .base {
    .cardsContainer {
      .card {
        margin: 20px;
        width: 340px;
        margin: 20px 90px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  .base {
    .cardsContainer {
      .card {
        width: 350px;
        font-size: 18px;

        .customCardTitle div {
          font-size: 30px;
        }
      }
    }
  }
}
