@import 'style/vars';

.base {
  form {
    padding: 15px 0;
    display: flex;

    > button {
      align-self: flex-start;
    }

    input[type='file'] {
      width: 100px;
      margin: $margin-base;
      padding: 3px;
      background-color: lightblue;
    }

    .filenameContainer {
      display: flex;
      margin-top: 10px;
      margin-left: 30px;
      .deleteFile {
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }
}
