@import 'style/vars';
@import 'style/mixins';

.base {
  .cardsContainer {
    @include cardsContainer;
    min-height: auto !important;
  }

  .error {
    @include input-error;
  }

  .card {
    @include card;
  }
}

.base1 {
  border: 1px solid rgba(191, 191, 191, 0.4);
  border-radius: 3px;
  box-shadow: 2px 2px 2px 1px rgba(191, 191, 191, 0.2);
  .row {
    font-size: 14px;
    padding: 8px;
    &:hover {
      background-color: #f3f6fe;
    }
    .checkbox {
      cursor: pointer;
      margin-right: 8px;
    }
  }
}
