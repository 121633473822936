@import 'style/vars';

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .textareaContainer {
    display: flex;
    flex-direction: row;
    textarea {
      border: solid 1px $light-grey-border-color;
      min-height: 90px;
      border-radius: 5px;
      padding: 5px;
      margin: 10px 0;
      box-shadow: 3px 3px 3px $light-grey-border-color;
    }
    .trashIcon {
      align-self: center;
      margin: 0px 15px;
      fill: $font-color-placeholder;
      width: 20px;
      max-width: 100%;
      cursor: pointer;
    }
  }

  .error {
    color: $font-color-error;
    font-size: $font-size-small-2;
    margin-left: 5px;
  }

  .uploadWrapper {
    text-align: center;
    position: relative;
    width: 250px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    svg {
      fill: $font-color-main;
      width: 30px;
      height: 30px;
    }
    div {
      font-size: 14px;
      align-self: center;
      margin: 0px 10px;
    }
  }

  .nextBtn {
    margin-top: 30px;
  }
}
