.base {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  width: 65px;
  min-height: 2rem;
  max-height: 3.8rem;
  overflow: hidden;
  &.opened {
    max-height: none;
  }
  &.closed {
    .withOverflow {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  svg {
    cursor: pointer;
    &:hover {
      background-color: #d6d6d6;
      border-radius: 50px;
    }
  }
  &.first {
    width: 12px;
    text-align: center;
    display: inline;
    svg {
      cursor: pointer;
    }
  }

  &.id {
    width: 15px;
    text-align: center;
    display: inline;
  }

  &.createdAt {
    width: 70px;
  }
  &.status {
    width: 100px;
  }
  &.name {
    width: 150px;
    word-break: break-all;
  }
  &.territories {
    width: 150px;
  }
  &.text,
  &.terms {
    min-width: 100px;
    width: calc(50vw - 450px);
  }
  &.report {
    width: 99px;
    text-align: center;
  }
}
