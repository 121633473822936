@import 'style/vars';
@import 'style/mixins';

$BLUE_COLOR: $blue;

.base {
    margin: 11px auto;
    text-align: center;

  .content {
    display: inline-block;
    width: 80%;
    text-align: center;

    .downloadCard {
      display: inline-block;
      padding: 10px;
      font-size: 18px;
      background-color: white;
      color: $BLUE_COLOR;
      width: 80%;
      max-width: 430px;
      box-sizing: border-box;
      text-align: center;
      border-radius: 35px;

      .cummulativeText {
        margin-top: 25px;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .spinner {
      align-self: center;
      margin: 10px;
      width: 50px;
      height: 100%;
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .buttonContainer {
      margin-top: 25px;
      button {
        width: 100%;
      }
    }
  }

  .error {
    margin: 20px 0;
    color: $font-color-error;

    .errorIcon {
      margin-bottom: 12px;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .progressBarContainer {
    margin: 20px 0;
  }
  .progressBar {
    height: 15px;
    width: 100%;
    background-color: #e0e0de;
    border-radius: 50px;

    .filler {
      height: 15px;
      background-color: $blue;
      border-radius: 50px;
      transition: width 5s ease;
      transition: width 5s;
      -webkit-transition: width 5s;
      -moz-transition: width 5s;
      display: block;
      transition: width 5s ease-in-out;
    }
  }
}
@media only screen and (min-width: $small-tablet-width) {
  .base {
    .content {
      .downloadCard {
        padding: 30px;
        font-size: 20px;
        .cummulativeText {
          font-size: 20px;
        }
      }
    }
  }
}
@media only screen and (min-width: $desktop-width) {
  .base {
    .content {
      .subtitle {
        font-size: 30px;
      }
    }
  }
}
