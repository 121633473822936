@import 'style/vars';
$bullet-color: rgb(26, 145, 3);
.base {
  .dot {
    height: 14px;
    width: 14px;
    border: 1px solid $light-grey-border-color;
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    margin: 1px;
  }
  .filled {
    background-color: $bullet-color;
  }
  .nomatch .dot {
    background-color: #b33030;
  }
}
