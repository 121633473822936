/* Overriding element */

.multiSelectContainer .searchBox {
  width: 90%;
  line-height: 1;
  font-size: 15px;
  color: #6f81a5;
  font-family: 'Inter', 'Nunito Sans', 'Helvetica Neue';
}

.multiSelectContainer .searchBox::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6f81a5;
  padding: 0;
  opacity: 1; /* Firefox */
  font-family: 'Inter', 'Nunito Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.multiSelectContainer .chip {
  font-weight: bold;
  font-size: 15px;
  font-family: 'Inter', 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.optionContainer li:hover,
.optionContainer .highlight {
  background: #4c7ff6;
  color: #fff;
}
