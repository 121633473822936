@import 'style/vars';

.base {
  color: $primary-color-dark;
  font-size: 12px;

  hr {
    border-top: 1px solid $primary-color-dark;
    margin: 30px 0;
  }

  .item {
    margin-bottom: 15px;
  }
  .label {
    font-weight: bold;
  }
}
