@import 'style/vars';

.base {
  background-color: white;
  color: $primary-color-dark;
  min-width: 600px;
  margin: 40px;
  padding: 60px;

  .searchInfo {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    margin-top: 20px;;

    .btnContainer {
      margin-right: 30px;

      button {
        font-size: 12px;
        background-color: #08ad5c;
      }
    }
  }
}
