@import 'style/vars';

.base {
  margin: 30px;

  .goback {
    a {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin-top: 10px;
      font-size: 18px;
      color: white;
      svg {
        margin-right: 5px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .title {
    margin: 25px 0;
    font-weight: bold;
    font-size: $font-size-h2;
    color: white;
  }
}
