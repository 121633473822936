@import 'style/vars';
@import 'style/mixins';

$INPUT_FONT_SIZE: 16px;
$SELECTED_COLOR: rgb(86, 146, 250);

.base {
  @include cardsContainer;

  .card {
    @include card;
    text-align: center;

    .form {
      margin-top: 15px;
    }
  }

  .error {
    color: $font-color-error;
    font-size: $font-size-small-2;
    margin-bottom: 10px;
    text-align: center;
  }
}
