@import 'style/vars';

$PADDING: 30px;

@mixin cardsContainer {
  display: flex;
  justify-content: center;
  padding-top: $PADDING;
  min-height: calc(100vh - #{$header-height} - #{$PADDING});
  &.bigHeader {
    min-height: calc(100vh - #{$header-height-big} - #{$PADDING});
  }

  @media only screen and (min-width: $small-tablet-width) {
    min-height: calc(100vh - #{$header-height} - 100px);
    align-items: center;
  }
  @media only screen and (min-width: $desktop-width) {
    flex-direction: row;
  }
}

@mixin card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 25px;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  .cardContent {
    margin-bottom: 20px;

    .buttonContainer {
      margin-top: 20px;
      button {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: $small-tablet-width) {
    width: auto;
    min-height: auto;
    min-width: 400px;
    border-radius: 15px;
  }
}

@mixin text-input {
  box-sizing: border-box;
  border-radius: 3px;
  border-color: transparent;
  background-color: $input-background-color;
  padding: 10px;
  color: $font-color-main;
  font-family: $font-family-main;
}

@mixin errorMessage {
  color: $font-color-error;
  text-align: center;
  margin: 30px;
}

@mixin input-error {
  color: $font-color-error;
  font-size: $font-size-small-2;
  margin-left: 5px;
  margin-top: 3px;
}
