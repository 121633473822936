@import 'style/vars';

.base {
  display: flex;
  flex-direction: column;
  height: 985px;
  background-image: url('/assets/images/green-landscape-2.jpg');
  background-size: auto 100%;
  background-position: right;
  color: white;

  .text {
    color: white;
    margin-top: 70%;
    text-align: center;
    font-size: 18px;

    div {
      margin: 30px 100px;
    }
    a {
      color: white;
      font-weight: bold;
    }
  }

  .footer {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .logo {
      height: 60px;
      margin-left: 60px;
      margin-bottom: 15px;
    }
  }
}
