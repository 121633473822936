@import 'style/vars';
@import 'style/mixins';

.card {
  @include card;
  text-align: center;

  .cardContent {
    margin: 20px;

    svg {
      width: 100%;
      height: 7em;
      color: $green;
      margin-bottom: 20px;
    }

    .iconWarning svg {
      color: $dark-grey;
    }
  }
  .link {
    margin: 10px;
    cursor: pointer;
    text-decoration: underline;
  }
}
