@import 'style/vars';

.base {
  background-color: #f3f6fe;
  margin-bottom: 10px;
  color: $font-color-placeholder;

  .header {
    padding: 18px 15px 18px 15px;
    border: 1px solid #f3f6fe;
    cursor: pointer;

    &:hover {
      background-color: #e6ecfa;
    }
  }

  .suggestedWordsContainer {
    min-height: 10px;
    padding: 0 10px 10px 10px;

    .suggestedWord {
      display: inline-block;
      background-color: rgb(204, 204, 237);
      color: #333;
      padding: 3px 7px;
      margin: 2px 5px 0 0;
      border-radius: 6px;

      &:hover {
        background: rgb(106, 106, 226);
        color: #eee;
        cursor: pointer;
      }
    }
  }

  .optionsContainer {
    position: relative;
    .optionsContainerInner {
      display: none;
      position: relative;
      color: black;
      top: 100%;
      left: 0;
      width: calc(100% - 2px);
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: 0px 1px 5px 0 lightgrey;
      z-index: 999;
      max-height: 200px;
      overflow-y: auto;

      &.isOpen {
        display: block;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: 18px;
      }

      .optionRow {
        padding: 6px 15px 3px 15px;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          background-color: #f3f6fe;
        }

        &.selectAllRow {
          border-bottom: 1px solid #ccc;
          padding-bottom: 7px;
          margin-bottom: 7px;
        }

        &.isParent {
          position: relative;
          color: #000;

          &::before {
            position: absolute;
            content: '+';
            left: 1px;
            top: 1px;
            font-weight: normal;
            font-size: 1.2rem;
          }

          &.isOpen {
            &::before {
              content: '-';
            }
          }
        }

        &.optionDisabled {
          color: rgb(189, 188, 188);
          cursor: default;
        }

        .checkBox {
          position: relative;
          margin-right: 8px;
          &.partialSelected {
            &:after {
              background-color: gray;
            }
          }
        }
      }

      .optionRowChildren {
        .optionRow {
          margin-left: 10px;
        }
      }
    }
  }
}
