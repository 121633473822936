@import 'style/vars';
@import 'style/mixins';

$INPUT_FONT_SIZE: 16px;
$SELECTED_COLOR: rgb(86, 146, 250);

.base {
  @include cardsContainer;
  .card {
    @include card;
    text-align: center;
  }

  .formContainer {
    display: flex;
    justify-content: center;

    .form {
      width: 100%;

      .inputContainer {
        margin: 20px 0;
        position: relative;
        display: flex;
        flex-direction: column;

        label {
          position: absolute;
          pointer-events: none;
          transform: translate(0, 19px) scale(1);
          transform-origin: top left;
          transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
          color: #6f81a5;
          font-size: $INPUT_FONT_SIZE;
          line-height: 1;
          left: 16px;

          &.filled {
            transform: translate(0, 10px) scale(0.8);
            color: grey;
          }
        }

        &:focus-within label {
          transform: translate(0, 10px) scale(0.8);
          color: $SELECTED_COLOR;
        }

        input {
          padding: 25px 15px 10px 15px;
          font-size: $INPUT_FONT_SIZE;
          &:focus {
            border: 1px solid $SELECTED_COLOR;
          }
        }

        .inputError {
          color: $font-color-error;
          font-size: $font-size-small-2;
          text-align: left;
          margin-left: 10px;
          margin-top: 4px;
        }
      }

      .btnContainer {
        float: right;
        width: 100%;
        margin: 20px 0;
        button {
          width: 100%;
          margin: 0;
        }
      }

      .error {
        margin-top: 10px;
        color: $font-color-error;
        font-size: $font-size-small-2;
      }
    }
  }
}
