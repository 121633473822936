@import 'style/vars';

$progress-tracker-bg: darken($dark-grey, 12%);

.base {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;

  .uploadWrapper {
    background-color: white;
    padding: 40px;
    border: 2px dotted $font-color-main;
    border-radius: 6px;
    text-align: center;
    position: relative;

    button {
      height: 70px;
      border-radius: 5px;
      margin-bottom: 8px;
    }
    .uploadInput {
      position: absolute;
      cursor: pointer;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      pointer-events: all;
    }
  }
  .selectedDocumentsText {
    font-size: $font-size-small-2;
    margin: 30px 0 10px;
    align-self: flex-end;
    font-weight: bold;
  }

  .documentsList {
    box-shadow: 1px 0px 5px 0px $light-grey-border-color;
    background-color: #fff9;
  }
  .documentRow {
    display: flex;
    padding: 8px 15px;
    &.odd {
      background-color: $light-grey-background;
    }

    .documentFilename {
      width: 50%;
    }
    div {
      align-self: center;
    }

    div:last-child {
      margin-left: auto;
    }
  }

  .trashIcon {
    align-self: center;
    fill: $font-color-placeholder;
    width: 20px;
    max-width: 100%;
    cursor: 'pointer';
  }

  .backNextBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    height: 40px;
  }

  .progressBarContainer {
    margin-top: 50px;
    font-size: 12px;
  }
  .progressBar {
    height: 18px;
    width: 100%;
    background-color: rgb(215, 215, 215);
    border-color: grey;
    border-radius: 15px;
    margin-top: 5px;

    div {
      background-color: $progress-tracker-bg;
      height: 100%;
      text-align: center;
      color: white;
      border-radius: 15px 0 0 15px;
    }
  }
}
