@import 'style/vars';
@import 'style/mixins';

.base {
  @include cardsContainer;

  display: flex;
  flex-direction: row !important;

  @media only screen and (max-width: 860px) {
    flex-direction: column-reverse !important;
  }

  .disclaimer {
    margin-left: 30px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 330px;

    @media only screen and (max-width: 860px) {
      margin-left: 0;
    }
  }
}
