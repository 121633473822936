@import 'style/vars';
@import 'style/mixins';

$INPUT_FONT_SIZE: 16px;
$SELECTED_COLOR: rgb(86, 146, 250);

.base {
  @include cardsContainer;
  text-align: center;
  .card {
    @include card;
    .spinner {
      margin-top: 30px;
    }
    .spinner svg {
      animation-name: spin;
      animation-duration: 1500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .error {
      margin-top: 20px;

      a {
        font-weight: bold;
        color: $primary-color;

        &:hover {
          color: $primary-color-dark;
        }
      }
    }
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
