@import 'style/vars';

.base {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $light-grey-border-color;
  padding: 10px 0;

  &:hover {
    background-color: rgba(90, 193, 252, 0.125);
  }
}
