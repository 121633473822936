@import 'style/vars';

.base {
  display: flex;
  color: #aaa9a9;
  font-weight: bold;
  font-size: 12px;
  flex-direction: column;
  .block {
    width: 100%;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      padding-right: 3px;
    }
  }

  .done {
    color: $primary-color;
  }
}

@media only screen and (min-width: 340px) {
  .base {
    flex-direction: row;
  }
}

@media only screen and (min-width: $small-tablet-width) {
  .base {
    font-size: $font-size-small-2;
  }
}
