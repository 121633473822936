@import 'style/vars';
@import 'style/mixins';

$row-padding: 10px 20px;
.base {
  .tableContainer {
    display: flex;
    flex-direction: column;
    min-width: 1350px;
    font-family: $font-family-secondary;
    line-height: 1.3;
    background-color: rgb(249, 247, 247);
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px #61616124;
    margin: 20px;

    .row:not(:first-child) {
      border-top: 1px solid $light-grey-border-color;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: $row-padding;
      font-size: 14px;

      &:hover {
        background-color: rgba(179, 223, 251, 0.26);
      }

      &.header {
        background-color: $primary-color-dark;
        color: white;
        border-radius: 8px 8px 0 0;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .column {
        width: 130px;
        overflow: hidden;
        margin-right: 10px;
        &.user {
          width: 350px;
          word-break: break-all;
          a {
            cursor: pointer;
            color: black;
            .name {
              font-weight: bold;
              font-size: 16px;

              svg {
                padding-left: 12px;
              }
            }
            .email {
              font-size: 14px;
              color: rgb(82, 82, 82);
            }
            &:hover {
              color: $primary-color;
              .email {
                color: $primary-color;
              }
            }
          }
        }
        &.createdAt {
          width: 120px !important;
        }
        &.date {
          input {
            width: 80px !important;
          }
        }
        &.status {
          .pending {
            color: rgb(237, 154, 0);
            font-weight: bold;
          }
        }
      }
    }

    .loading {
      margin: 20px;
    }

    .error {
      color: $font-color-error;
      font-size: $font-size-small-2;
      margin: 10px;
      text-align: center;
    }
  }
}
